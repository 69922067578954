import React, { useCallback, useRef, useEffect } from 'react'
import useBlockScroll from '../utility/useBlockScroll'


const PrivacyModal = ({ show, handleClose, text }) => {
  
  const modalRef = useRef(null);

  const closeOnEscape = useCallback(e => {
    if (e.key === 'Escape') {
      handleClose();
    }
  }, [handleClose]);

  useBlockScroll(show);

  useEffect(() => {
    if (modalRef.current){
      modalRef.current.focus();
    }
  });

  return (show && text ?
    (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-tabindex
      <div ref={modalRef} className="privacy-modal d-flex justify-content-center align-items-center" onKeyDown={closeOnEscape} tabIndex={0}>
        <button onClick={handleClose} className="privacy-modal__close icon-btn" aria-label="Chiudi">
          <i className="nj-icon nj-icon-cross size-48"></i>
        </button>
        <div className="privacy-modal__content" dangerouslySetInnerHTML={{__html: text }}></div>
      </div>
    ) : null
  );
}

export default PrivacyModal;