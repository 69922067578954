import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Link } from './link'


const CorrelatedArticle2 = ({ data: { imageBack } }) => {
  const { imageFront, textSx, descriptionText, btnText, link } = imageBack.content;
  return (
    <div className="correlated-article2 mb-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Link to={link.value} className="correlated-article2__link position-relative d-flex flex-column justify-content-end p-3 p-lg-6">
              <Img image={getImage(imageBack.node)} alt={imageBack.node.description || ''} 
                className="correlated-article2__bg" />
              <div className="correlated-article2__content position-relative flex-fill d-inline-flex flex-column flex-lg-row">
                <div className={`correlated-article2__img${textSx ? ' order-lg-2': ''}`}>
                  {imageFront && imageFront.node ? (
                    <Img image={getImage(imageFront.node)} alt={imageFront.node.description} />
                  ): null}
                </div>
                <div className="correlated-article2__box p-4 p-lg-5 p-lg-x-6 d-flex flex-column justify-content-between">
                  {descriptionText && descriptionText.value ? (
                  <div className="correlated-article2__text text-white text-center font-weight-black"
                    dangerouslySetInnerHTML={{__html: descriptionText.value}}></div>): null}
                  <button className="nj-btn nj-btn--light nj-btn--lg mt-2 align-self-center">{btnText.value}</button>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CorrelatedArticle2;
export const correlatedArticle2Fragment = graphql`fragment BannerinformativoFragment on LiferayBannerinformativo{
  siteId
  articleId
  imageBack {
    node {
      description
      gatsbyImageData(width: 1108)
    }
    content {
      textSx
      imageFront {
        node {
          description
          gatsbyImageData(width: 590)
        }
      }
      descriptionText {
        value
      }
      btnText {
        value
      }
      link {
        value
      }
    }
  }
}`;
