import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage as Img, getImage, withArtDirection } from 'gatsby-plugin-image'
import { Link } from './link'


const CorrelatedArticle = ({ data: { mainTitle, imageDesktop, imageMobile, articleLink, btnText }}) => {
  const sources = useMemo(() => {
    if (!imageDesktop) return null;
    return imageMobile ? withArtDirection(getImage(imageDesktop.node), [
      {
        media: "(max-width: 576px)",
        image: getImage(imageMobile.node),
      },
    ]): getImage(imageDesktop.node);
  }, [imageMobile, imageDesktop]);

  return (
    <div className="correlated-article">
      <div className="container">
        {mainTitle && mainTitle.value ? (
          <div className="row">
            <div className="col-12">
              <div className="correlated-article__title" dangerouslySetInnerHTML={{__html: mainTitle.value }}></div>
            </div>
          </div>
        ) : null }
        <div className="row">
          <div className="col-12">
            <Link to={articleLink.value} className="correlated-article__link position-relative d-flex flex-column justify-content-end p-4 p-lg-5">
              <Img image={sources} alt={imageDesktop.node.description} className="correlated-article__img" />
              <div className="correlated-article__content">
                <div className="position-relative text-white" 
                  dangerouslySetInnerHTML={{__html: imageDesktop.content.articleTitle.value }}></div>
                {btnText && btnText.value ? (
                  <button className="nj-btn nj-btn--light mt-5">{btnText.value}</button>
                ): null}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CorrelatedArticle;
export const correlatedArticleFragment = graphql`fragment ArticoloCorrelatoFragment on LiferayArticolocorrelato{
  siteId
  articleId
  mainTitle {
    value
  }
  imageDesktop {
    node {
      description
      gatsbyImageData(width: 1108)
    }
    content {
      articleTitle {
        value
      }
    }
  }
  imageMobile {
    node {
      gatsbyImageData(width: 540)
    }
  }
  btnText {
    value
  }
  articleLink {
    value
  }
}`;
