import React, { useMemo, useState, useCallback } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage as Img, getImage, withArtDirection } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import { Link } from './link'
import Breadcrumb from './elements/breadcrumb'
import withPreview from '../utility/with-preview'
import Marker from '../images/icons/marker.svg'
import ArrowForwardIcon from '../images/icons/material/arrow_forward.svg'


const settings = {
  dots: true,
  infinite: false,
  autoplay: false,
  autoplaySpeed: 3000,
  arrows: false,
  swipe: true,
  responsive: [
    {
      breakpoint: 999999,
      settings: "unslick"
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 6
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '40px'
      }
    }
  ]
};

const HeroIconsLink = ({ data: { imageDesktop, imageTablet, imageMobile, mainTitle, iconTitle }, page }) => {
  const sources = useMemo(() => {
    return imageMobile || imageTablet ? withArtDirection(getImage(imageDesktop.node), []
    .concat(
      imageTablet ? {
        media: "(max-width: 1024px)",
        image: getImage(imageTablet.node),
      }: []
    ).concat(
      imageMobile ? {
        media: "(max-width: 576px)",
        image: getImage(imageMobile.node),
      }: []
    )): getImage(imageDesktop.node);
  }, [imageMobile, imageTablet, imageDesktop]);

  const [hover, setHover] = useState(false);
  const onHover = useCallback(() => setHover(true), []);
  const onOut = useCallback(() => setHover(false), []);

  return (
    <div className="hero-icons-link">
      <Img image={sources} alt={imageDesktop.node.description || ''} className="hero-icons-link__img" />
      <div className="container">
        <Breadcrumb page={page} />
        {mainTitle && mainTitle.value ? (
          <div className={`row${hover ? ' d-none': ''}`}>
            <div className={`col-12 mt-6 mt-lg-7`}>
              <h1 className="hero-icons-link__title mb-2" dangerouslySetInnerHTML={{ __html: mainTitle.value }}></h1>
            </div>
          </div>
        ) : null}
      </div>
      {iconTitle && iconTitle.length ? (
        <Slider {...settings} className="hero-icons-link__slider d-lg-flex justify-content-lg-between pb-xl-4">
          {iconTitle.slice(0, 7).map((item, i) => (
            <div key={i}>
              <Link to={item.content && item.content.iconLink && item.content.iconLink.value} 
                onMouseOver={onHover} onFocus={onHover} onMouseOut={onOut} onBlur={onOut}
                className="hero-icons-link__slide px-3 px-xl-2 d-inline-flex flex-column text-center align-items-center justify-content-end">
                <div className="hero-icons-link__marker mb-2 d-inline-flex justify-content-center align-items-center">
                  <Marker />
                </div>
                <div className="font-weight-bold" dangerouslySetInnerHTML={{ __html: item.value }}></div>
                {item.content && item.content.iconLink && item.content.iconLink.value ? (
                  <div className="hero-icons-link__link">
                    <ArrowForwardIcon className="material-svg text-blue-corporate" />
                  </div>
                ) : null}
                {item.content && item.content.iconDescription && item.content.iconDescription.value ? (
                  <div className="hero-icons-link__description"
                    dangerouslySetInnerHTML={{ __html: item.content.iconDescription.value }}></div>
                ) : null}
              </Link>
            </div>
          ))}
        </Slider>
      ) : null}
    </div>
  );
}

export default withPreview(HeroIconsLink);
export const heroIconsLinkFragment = graphql`fragment HeroIconeLinkFragment on LiferayHeroiconelink{
  siteId
  articleId
  imageDesktop {
    node {
      description
      gatsbyImageData(width: 1440)
    }
  }
  imageTablet {
    node {
      gatsbyImageData(width: 1024)
    }
  }
  imageMobile {
    node {
      gatsbyImageData(width: 576)
    }
  }
  mainTitle {
    value
  }
  iconTitle {
    value
    content {
      iconDescription {
        value
      }
      iconLink {
        value
      }
    }
  }
}`;