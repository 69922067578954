import React from 'react'
import { graphql } from 'gatsby'
import Slider from 'react-slick'
import { GatsbyImage as Img, getImage, withArtDirection } from 'gatsby-plugin-image'
import { Link } from './link'


const PrevArrow = ({ onClick, className }) => <button onClick={onClick} className={`icon-btn ${className}`}>
  <i className="nj-icon nj-icon-arrowleft nj-icon--border nj-icon--blue-corporate size-40"></i>
</button>;
const NextArrow = ({ onClick, className }) => <button onClick={onClick} className={`icon-btn ${className}`}>
  <i className="nj-icon nj-icon-arrowright nj-icon--border nj-icon--blue-corporate size-40"></i>
</button>;

const settings = {
  dots: true,
  autoplay: false,
  autoplaySpeed: 3000,
  arrows: true,
  swipe: true,
  slidesToShow: 3,
  infinite: false,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 999999,
      settings: "unslick"
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '26%'
      }
    }
  ]
};

const getSources = logo => {
  if (!logo.node?.desktop) return null;
  return logo.node.mobile ? withArtDirection(getImage(logo.node.desktop), [
    {
      media: "(max-width: 576px)",
      image: getImage(logo.node.mobile),
    },
  ]): getImage(logo.node.desktop);
};

const ListaClienti = ({ data: { mainTitle, mainSubtitle, customerLogo } }) => {
  return (
    <div className="lista-clienti mb-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            {mainTitle && mainTitle.value ? (
              <div className="lista-clienti__title text-center">{mainTitle.value}</div>
            ) : null}
            {mainSubtitle && mainSubtitle.value ? (
              <div className="lista-clienti__subtitle text-center" dangerouslySetInnerHTML={{ __html: mainSubtitle.value }}>
              </div>
            ) : null}
          </div>
        </div>
        {customerLogo && customerLogo.length ? (
          <div className="row">
            <div className="col-12 px-0 py-4 pb-lg-0 px-sm-3">
              <Slider {...settings} className="lista-clienti__slider mb-7 mb-md-5 mb-xl-0 d-lg-flex justify-content-lg-between">
                {customerLogo.map((item, i) => (
                  <div key={i}>
                    <Link to={item.content && item.content.customerLink && item.content.customerLink.value} 
                      className="lista-clienti__item d-flex justify-content-center">
                      <Img image={getSources(item)} alt={item.node.description || ''} />
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ListaClienti;
export const listaClientiFragment = graphql`fragment ListaClientiFragment on LiferayListaclienti{
  siteId
  articleId
  mainTitle {
    value
  }
  mainSubtitle {
    value
  }
  customerLogo {
    node {
      description
      desktop: gatsbyImageData(height: 140, layout: FIXED)
      mobile: gatsbyImageData(height: 180, layout: FIXED)
    }
    content {
      customerLink {
        value
      }
    }
  }
}`