import React from "react"
import { graphql } from 'gatsby'


const BoxDatiNumerici = ({ data: { mainTitle, mainSubtitle, number } }) => {
  return (
    <div className="section box-dati-numerici mb-5 pb-5">
      <div className="container">
        {mainTitle && mainTitle.value ? (
          <div className="row">
            <div className="col-lg-10 offset-lg-1 text-center">
              <h3 className="box-dati-numerici__title">{mainTitle.value}</h3>
            </div>
          </div>
        ) : null}
        {mainSubtitle && mainSubtitle.value ? (
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="box-dati-numerici__subtitle text-center" 
                dangerouslySetInnerHTML={{ __html: mainSubtitle.value }}>
              </div>
            </div>
          </div>
        ) : null}
        {number ? (
          <div className="row mt-2">
            <div className="col-sm-8 offset-sm-2 col-lg-10 offset-lg-1">
              <div className="row px-md-4 px-lg-0 px-xl-4">
                {number.map((item, i) => (
                  <div className="col-lg-4 mb-4 mb-lg-0" key={i}>
                    <div className="box-dati-numerici__box d-flex flex-column h-100 px-3">
                      <h1 className="box-dati-numerici__number text-white mt-auto mb-0">{item.value}</h1>
                      {item.content && item.content.numberText && item.content.numberText.value ? (
                        <div className="box-dati-numerici__text text-white" 
                          dangerouslySetInnerHTML={{ __html: item.content.numberText.value }}>
                        </div>
                      ): null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default BoxDatiNumerici;
export const boxDatiNumericiFragment = graphql`fragment BoxDatiNumericiFragment on LiferayBoxdatinumerici{
  siteId
  articleId
  mainTitle {
    value
  }
  mainSubtitle {
    value
  }
  number {
    value
    content {
      numberText {
        value
      }
    }
  }
}`;

