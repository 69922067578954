import React, { useState, useEffect, useRef, useCallback } from 'react'
import { graphql } from "gatsby"
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios"
import NjSelect from './nj-select'
import PrivacyModal from './privacy-modal'
import settoriList from '../data/contact-form/settori/settori.json'
import provinceList from '../data/contact-form/province/province.json'


const FORM_ENDPOINT = process.env.GATSBY_FORM_ENDPOINT;
const FORM_GROUPID = process.env.GATSBY_FORM_GROUPID || '5879978';
const mandatoryFields = [
  'nome', 'cognome', 'email', 'comune', 'privacy'
];

const ContactForm = ({ data: { image, privacyText, introText, formTitle } }) => {
  const [formData, setFormData] = useState({
    nome: '',
    cognome: '',
    telefono: '',
    email: '',
    settore: '',
    azienda: '',
    comune: '',
    provincia: '',
    messaggio: '',
    privacy: false,
    giacliente: false
  });
  const onChange = useCallback((e) => {
    const { name, value, checked, type } = e.target;
    setFormData(data => ({ ...data, [name]: type === 'checkbox' ? checked : value }));
  }, []);
  const setSettore = useCallback((value) => setFormData(data => ({ ...data, settore: value })), []);
  const setProvincia = useCallback((value) => setFormData(data => ({ ...data, provincia: value })), []);

  const [privacyVisible, setPrivacyVisible] = useState(false);
  const showPrivacy = useCallback((e) => {
    e.preventDefault();
    setPrivacyVisible(true);
  }, []);
  const closePrivacy = useCallback(() => setPrivacyVisible(false), []);

  const formRef = useRef(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [submitted, setSubmitted] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [remoteError, setRemoteError] = useState(null);
  const [errorsMap, setErrorsMap] = useState({});

  const [focusMap, setFocusMap] = useState({});
  const onFocus = useCallback((e) => {
    const { name } = e.target;
    setFocusMap(map => ({ ...map, [name]: true }));
  }, []);
  const onBlur = useCallback((e) => {
    const { name } = e.target;
    setFocusMap(map => ({ ...map, [name]: false }));
  }, []);

  const validateForm = useCallback((formData) => {
    const errors = mandatoryFields.reduce((errors, field) => {
      return { ...errors, [field]: !formData[field] }
    }, {});
    // console.log('errors: ', errors);
    setErrorsMap(errors);
    const formValid = Object.values(errors).find(val => !!val) === undefined;
    if (!formValid) {
      setRemoteError(null);
    }
    return formValid;
  }, []);

  useEffect(() => {
    if (validationError) {
      const formValid = validateForm(formData);
      // console.log('formValid: ', formValid);
      if (formValid) {
        setValidationError(null);
      }
    }
  }, [validationError, formData, validateForm]);

  const [loading, setLoading] = useState(false);
  const submit = useCallback(async (e) => {
    e.preventDefault();
    if (!executeRecaptcha) {
      console.warn('Execute recaptcha not yet available');
      return;
    }
    const formDataToSubmit = new FormData(e.target);
    if (!validateForm(formData)) {
      console.warn('Form data not valid');
      setValidationError('Completa i dati mancanti');
      return;
    }
    setValidationError(null);
    setRemoteError(null);
    if (!formDataToSubmit.get('giacliente')) {
      formDataToSubmit.set('giacliente', 'no');
    }
    const token = await executeRecaptcha('formEnti');
    if (token) {
      formDataToSubmit.set('grecaptcha', token);
    }
    const queryStringSubmit = new URLSearchParams(formDataToSubmit).toString();
    console.log('About to submit: ', queryStringSubmit);
    setLoading(true);

    try {
      const { data } = await axios.post(`${FORM_ENDPOINT}`,
        queryStringSubmit,
        {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }
      );
      console.log('Submitted: ', data);
      if (data.error) {
        console.warn('Si è verificato un errore: ', data);
        setRemoteError(data.error.message || 'Si è verificato un errore');
      } else {
        setSubmitted(true);
        if (formRef.current) {
          formRef.current.scrollIntoView();
        }
      }
    } catch (err) {
      console.error('error: ', err);
      if (err.response) {
        console.error('errore nella chiamata: ', err.response);
      }
      setSubmitted(false);
      setRemoteError('Si è verificato un errore');
    }
    setLoading(false);
  }, [validateForm, formData, executeRecaptcha]);

  const groupClass = (name) => `nj-form-group nj-form-group--icon-right${focusMap[name] ? ' is-focused' : ''}${formData[name] ? ' is-filled' : ''}${errorsMap[name] ? ' has-warning' : ''}`;

  return (
    <div className="contact-form">
      <div className="container my-5 pb-5">
        <div className="row">
          <div className="col-lg-5 px-md-0">
            <div className="contact-form__wrapper p-3 p-md-6 d-flex flex-column h-100">
              {image && image.node ? (
                <Img image={getImage(image.node)} alt={image.node.description || ''} className="contact-form__img" />
              ) : null}
              {image.content ? (
                <div className="contact-form__gradient-box position-relative text-white d-flex flex-column justify-content-end pl-4 pb-5 pr-4 pt-4 mt-auto">
                  {image.content.boxTitle && image.content.boxTitle.value ? (
                    <div className="contact-form__box-title contact-form__box-title--big mt-4">
                      {image.content.boxTitle.value}
                    </div>
                  ) : null}
                  {image.content.boxText && image.content.boxText.value ? (
                    <div className="contact-form__box-subtitle mt-2"
                      dangerouslySetInnerHTML={{ __html: image.content.boxText.value }}></div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
          <div ref={formRef} className="col-lg-7 px-md-0">
            <div className="contact-form__content py-5 px-3 px-md-5 pr-md-7 h-100">
              <div className="row">
                <div className="col-12">
                  <h3 className="contact-form__title text-uppercase">{formTitle ? formTitle.value : "Richiesta di contatto"}</h3>
                  <div className="contact-form__subtitle">
                    {introText && introText.value &&
                      <div dangerouslySetInnerHTML={{ __html: introText.value }}></div>
                    }
                  </div>
                </div>
              </div>
              {submitted ? (
                <div className="row">
                  <div className="col-12 text-center mt-lg-5 pt-lg-5">
                    <h3>Grazie per averci lasciato i tuoi dati</h3>
                  </div>
                </div>
              ) : (
                <form onSubmit={submit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={groupClass('nome')} >
                        <div className="contact-form__text">
                          <input type="text" className="nj-form-control" name="nome" id="contactFormNome"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.nome} />
                          <label htmlFor="contactFormNome" className="nj-label-floating">Nome</label>
                        </div>
                      </div>
                      {errorsMap.nome ? (
                        <span className="contact-form__warning">Specificare nome</span>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className={groupClass('cognome')}>
                        <div className="contact-form__text">
                          <input type="text" className="nj-form-control" name="cognome" id="contactFormCognome" minLength="5"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.cognome} />
                          <label htmlFor="contactFormCognome" className="nj-label-floating">Cognome</label>
                          {/* <span className="nj-help">Your lastname must have 5 characters</span> */}
                        </div>
                      </div>
                      {errorsMap.cognome ? (
                        <span className="contact-form__warning">Specificare cognome</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={groupClass('email')}>
                        <div className="contact-form__text">
                          <input type="email" className="nj-form-control" name="email" id="contactFormEmail" autoComplete="email"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.email} />
                          <label htmlFor="contactFormEmail" className="nj-label-floating">Indirizzo e-mail</label>
                          {/* <span className="nj-help">We'll never share your email with anyone else.</span> */}
                        </div>
                      </div>
                      {errorsMap.email ? (
                        <span className="contact-form__warning">Specificare email</span>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className={groupClass('telefono')}>
                        <div className="contact-form__text">
                          <input type="text" className="nj-form-control" name="telefono" id="contactFormTelefono"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.telefono} />
                          <label htmlFor="contactFormTelefono" className="nj-label-floating">Telefono</label>
                          {/* <span className="nj-help"></span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      {/* <div className={groupClass('settore')}>
                        <div className="contact-form__text">
                          <input type="text" className="nj-form-control" name="settore" id="contactFormSettore"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.settore} />
                          <label htmlFor="contactFormSettore" className="nj-label-floating">Settore</label>
                        </div>
                      </div> */}
                      <NjSelect id="contactFormSettore" placeHolder="Settore" name="settore" warning={errorsMap.settore}
                        options={settoriList.settori} option={formData.settore} setOption={setSettore} />
                      {/* {errorsMap.settore ? (
                        <span className="job-form__warning">Specificare settore</span>
                      ) : null} */}
                    </div>
                    <div className="col-md-6">
                      <div className={groupClass('azienda')}>
                        <div className="contact-form__text">
                          <input type="text" className="nj-form-control" name="azienda" id="contactFormAzienda"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.azienda} />
                          <label htmlFor="contactFormAzienda" className="nj-label-floating">Ente/azienda</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={groupClass('comune')}>
                        <div className="contact-form__text">
                          <input type="text" className="nj-form-control" name="comune" id="contactFormComune"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.comune} />
                          <label htmlFor="contactFormComune" className="nj-label-floating">Comune</label>
                        </div>
                      </div>
                      {errorsMap.comune ? (
                        <span className="contact-form__warning">Specificare comune</span>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      {/* <div className={groupClass('provincia')}>
                        <div className="contact-form__text">
                          <input type="text" className="nj-form-control" name="provincia" id="contactFormProvincia"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.provincia} />
                          <label htmlFor="contactFormProvincia" className="nj-label-floating">Provincia</label>
                        </div>
                      </div> */}
                      <NjSelect id="contactFormProvincia" placeHolder="Provincia" name="provincia"
                        warning={errorsMap.provincia}
                        options={provinceList.province} option={formData.provincia} setOption={setProvincia} />
                      {/* {errorsMap.provincia ? (
                        <span className="job-form__warning">Specificare provincia</span>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className={groupClass('messaggio')}>
                        <div className="contact-form__textarea">
                          <textarea className="nj-form-control" id="contactMessage" name="messaggio" cols="30" rows="3"
                            onFocus={onFocus} onBlur={onBlur} onChange={onChange} value={formData.messaggio} >
                          </textarea>
                          <label htmlFor="contactMessage" className="nj-label-floating">Messaggio</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="nj-form-group">
                        <div className="nj-checkbox">
                          <label className="contact-form__privacy">
                            <input type="checkbox" name="giacliente" value="si"
                              checked={formData.giacliente} onChange={onChange} />
                            Sono un cliente ENGIE
                            <span className="nj-checkbox__decorator">
                              <span className="nj-checkbox__check"></span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="nj-form-group">
                        <div className="nj-checkbox">
                          <label className="contact-form__privacy">
                            <input type="checkbox" name="privacy" value="true"
                              checked={formData.privacy} onChange={onChange} />
                            Acconsento al trattamento dei dati personali per le finalità
                            <span className="nj-checkbox__decorator">
                              <span className="nj-checkbox__check"></span>
                            </span>
                          </label>
                          <span> </span>
                          <button onClick={showPrivacy} className="contact-form__privacy-link">
                            indicate
                          </button>
                        </div>
                      </div>
                      {errorsMap.privacy ? (
                        <span className="contact-form__warning">
                          Acconsentire al trattamento dei dati
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="nj-form-group">
                        <input type="hidden" name="groupId" value={FORM_GROUPID} />
                        <input type="hidden" name="companyId" value="10201" />
                        <input type="hidden" name="categoria" value="" />
                        <button type="submit" className="nj-btn nj-btn--primary" disabled={loading}>
                          {loading ? (
                            <>
                              Loading...
                              <div className="nj-spinner nj-spinner--xs ml-1" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            </>
                          ) : (
                            <span>Invia</span>
                          )}
                        </button>
                        {validationError ? (
                          <span className="ml-3 contact-form__warning">{validationError}</span>
                        ) : null}
                        {remoteError ? (
                          <span className="ml-3 contact-form__warning">{remoteError}</span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <PrivacyModal show={privacyVisible} handleClose={closePrivacy} text={privacyText && privacyText.value} />
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
export const contactFormFragment = graphql`fragment ContactFormFragment on LiferayForm_Contatti{
  siteId
  articleId
  image {
    node {
      description
      gatsbyImageData(width: 670)
    }
    content {
      boxTitle {
        value
      }
      boxText {
        value
      }
    }
  }
  introText {
    value
  }
  privacyText {
    value
  }
  formTitle {
    value
  }
}`;
