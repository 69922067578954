import React, { useState, useCallback } from "react";
import { graphql } from "gatsby";
import Slider from "react-slick";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import { Link } from "./link";
import VideoModal from "./video-modal";
import ChevronRightIcon from "../images/icons/material/chevron_right.svg";
import PlayArrowIcon from "../images/icons/material/play_arrow.svg";

const PrevArrow = ({ onClick, className }) => (
  <button onClick={onClick} className={`icon-btn ${className}`}>
    <i className="nj-icon nj-icon-arrowleft nj-icon--border nj-icon--blue-corporate size-60"></i>
  </button>
);
const NextArrow = ({ onClick, className }) => (
  <button onClick={onClick} className={`icon-btn ${className}`}>
    <i className="nj-icon nj-icon-arrowright nj-icon--border nj-icon--blue-corporate size-60"></i>
  </button>
);

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: true,
  swipe: true,
  slidesToShow: 3,
  prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Carousel = ({
  data: { mainTitle, carouselImage, ctaShowMore, btnText },
}) => {
  const [currentVideo, setCurrentVideo] = useState(null);
  const showVideo = useCallback((content) => {
    if (content && content.videoLink && content.videoLink.value) {
      setCurrentVideo(content.videoLink.value);
    }
  }, []);
  const closeVideo = useCallback(() => setCurrentVideo(null), []);
  const effectiveSettings = {
    ...settings,
    infinite: carouselImage.length > 3,
  };
  return (
    <div className="carousel mb-5 pb-5">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            {mainTitle.content &&
            mainTitle.content.mainIcon &&
            mainTitle.content.mainIcon.value ? (
              <i
                className={`nj-icon nj-icon-${mainTitle.content.mainIcon.value} nj-icon--blue-corporate size-126`}
              ></i>
            ) : null}
            {mainTitle.value ? (
              <h2 className="carousel__title">{mainTitle.value}</h2>
            ) : null}
            {mainTitle.content &&
            mainTitle.content.mainSubtitle &&
            mainTitle.content.mainSubtitle.value ? (
              <p className="text-blue-corporate font-weight-bold mb-5">
                {mainTitle.content.mainSubtitle.value}
              </p>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Slider {...effectiveSettings} className="carousel__slider">
              {carouselImage.map((item, i) => (
                <div key={i}>
                  <Link
                    to={
                      item.content &&
                      item.content.imageLink &&
                      item.content.imageLink.value
                    }
                    onClick={() => showVideo(item.content)}
                    className="carousel__slide d-flex flex-column align-items-start justify-content-end mx-md-2"
                  >
                    <Img
                      className="carousel__img w-100 h-100"
                      image={getImage(item.node)}
                      alt={
                        (item.content &&
                          item.content.descrizioneImmagine.value) ||
                        item.node.description ||
                        ""
                      }
                    />
                    {item.content &&
                    item.content.videoLink &&
                    item.content.videoLink.value ? (
                      <div className="carousel__play bg-blue-corporate">
                        <PlayArrowIcon className="text-white" />
                      </div>
                    ) : null}
                    <div className="carousel__content p-4">
                      {item.content &&
                        item.content.descrizioneImmagine.value && (
                          <h4 className="carousel__text text-white">
                            {item.content.descrizioneImmagine.value}
                          </h4>
                        )}
                      {ctaShowMore ? (
                        <div className="nj-link nj-link-icon">
                          <span className="text-white">Scopri di più</span>
                          <ChevronRightIcon className="material-svg text-white" />
                        </div>
                      ) : null}
                    </div>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {btnText &&
        btnText.value &&
        btnText.content &&
        btnText.content.btnLink &&
        btnText.content.btnLink.value ? (
          <div className="row">
            <div className="col-12 mt-4 text-md-center">
              <Link
                to={btnText.content.btnLink.value}
                className="nj-btn nj-btn--primary nj-btn--lg"
              >
                {btnText.value}
              </Link>
            </div>
          </div>
        ) : null}
      </div>
      <VideoModal
        show={!!currentVideo}
        handleClose={closeVideo}
        video={currentVideo}
      />
    </div>
  );
};

export default Carousel;
export const carouselFragment = graphql`
  fragment ProdotticorrelatiFragment on LiferayProdotticorrelati {
    siteId
    articleId
    mainTitle {
      value
      content {
        mainSubtitle {
          value
        }
        mainIcon {
          value
        }
      }
    }
    ctaShowMore
    carouselImage {
      node {
        description
        gatsbyImageData(width: 510)
      }
      content {
        descrizioneImmagine {
          value
        }
        imageLink {
          value
        }
        videoLink {
          value
        }
      }
    }

    btnText {
      value
      content {
        btnLink {
          value
        }
      }
    }
  }
`;
