import React from "react"
import { graphql } from "gatsby"
import BoxFull from "./box-full"
import TwoColumns from "./two-columns"
import IntroSimple from "./intro-simple"
import HeroIconsLink from "./hero-icons-link"
import DataIntro from "./data-intro"
import Carousel from "./carousel"
import CorrelatedArticle2 from "./correlated-article2"
import ListaClienti from './lista-clienti'
import BoxDatiNumerici from "./box-dati-numerici"
import BoxMappa from "./box-mappa"
import Attachments from "./attachments"
import ContactForm from "./contact-form"
import CorrelatedArticle from "./correlated-article"
import LaunchBox from "./launch-box"
import NumberBoxes from "./number-boxes"

import withPreview from "../utility/with-preview"


const PageFascia = ({ data }) => {
  // console.log('fascia: ', data)
  return (
    <React.Fragment>
      {{
        LiferayBannervideo: <BoxFull data={data} />,
        LiferayProdottoinformativo: <TwoColumns data={data} />,
        LiferayIntrosimple: <IntroSimple data={data} />,
        LiferayIntrodati: <DataIntro data={data} />,
        LiferaySiteHeader_Hero_Icone: <HeroIconsLink data={data} />,
        LiferayProdotticorrelati: <Carousel data={data} />,
        LiferayBannerinformativo: <CorrelatedArticle2 data={data} />,
        LiferayListaclienti: <ListaClienti data={data} />,
        LiferayBoxdatinumerici: <BoxDatiNumerici data={data} />,
        LiferayBoxmappa: <BoxMappa data={data} />,
        LiferayAllegati: <Attachments data={data} />,
        LiferayForm_Contatti: <ContactForm data={data} />,
        LiferayArticolocorrelato: <CorrelatedArticle data={data} />,
        LiferayChoiceselector: <LaunchBox data={data} />,
        LiferayBoxnumeri: <NumberBoxes data={data} />
      }[data.__typename]}
    </React.Fragment>
  );
}

export default withPreview(PageFascia);
export const fasceFragment = graphql`fragment FasceFragment on LiferayPaginainterna{
  fasce {
    __typename
    ... BannervideoFragment
    ... ProdottoinformativoFragment
    ... IntrosimpleFragment
    ... BannerinformativoFragment
    ... IntrodatiFragment
    ... ProdotticorrelatiFragment
    ... ListaClientiFragment
    ... BoxDatiNumericiFragment
    ... BoxMappaFragment
    ... AllegatiFragment
    ... ContactFormFragment
    ... ArticoloCorrelatoFragment
    ... ChoiceselectorFragment
    ... BoxnumeriFragment
  }
}`;
