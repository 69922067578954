import React, { useState, useCallback } from "react";
import { graphql } from "gatsby";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import regioni from "../data/box-mappa/regioni.json";
import Italymap from "../images/content/mappa-italia.svg";
import NordOvest from "../images/content/nord-ovest.svg";
import NordEst from "../images/content/nord-est.svg";
import AdriaticaSud from "../images/content/adriatica-sud.svg";
import Lazio from "../images/content/lazio.svg";
import Centro from "../images/content/centro.svg";
import Sardegna from "../images/content/sardegna.svg";

const regions = [
  "nord-ovest",
  "nord-est",
  "lazio",
  "sardegna",
  "adriatica-sud",
  "centro",
];
const targetsMap = {
  "#_x3C_nord-ovest_x3E__1_": "nord-ovest",
  "#_x3C_nord-est_x3E__1_": "nord-est",
  "#_x3C_lazio_x3E__1_": "lazio",
  "#_x3C_sardegna_x3E__1_": "sardegna",
  "#_x3C_adriatica-sud_x3E__1_": "adriatica-sud",
  "#_x3C_centro_x3E__1_": "centro",
};
const RegionMap = ({ region, ...other }) => {
  return (
    {
      "nord-ovest": <NordOvest {...other} />,
      "nord-est": <NordEst {...other} />,
      lazio: <Lazio {...other} />,
      sardegna: <Sardegna {...other} />,
      centro: <Centro {...other} />,
      "adriatica-sud": <AdriaticaSud {...other} />,
    }[region] || <div {...other}>Selezione non valida</div>
  );
};
const RegionData = ({ region }) => {
  const { titolo, contratti, collaboratori, indirizzo } = regioni[region] || {};
  return (
    <>
      <div
        className="box-mappa__region-title font-weight-black"
        dangerouslySetInnerHTML={{ __html: titolo }}
      ></div>
      <div className="row text-white">
        <div className="col-6 mt-4 mt-md-5">
          <i className={`nj-icon nj-icon-document nj-icon--white size-72`}></i>
          <h3 className="mt-2 font-weight-bold">{contratti}</h3>
          <div>
            <p>contratti gestiti dall’Area</p>
          </div>
        </div>
        <div className="col-6 mt-4 mt-md-5">
          <i className={`nj-icon nj-icon-expert nj-icon--white size-72`}></i>
          <h3 className="mt-2 font-weight-bold">{collaboratori}</h3>
          <div>
            <p>
              collaboratori nei
              <br />
              nostri uffici territoriali
            </p>
          </div>
        </div>
        <div
          className="col-12 mt-4 mt-md-5"
          dangerouslySetInnerHTML={{ __html: indirizzo }}
        ></div>
      </div>
    </>
  );
};
const FadeTransition = (props) => (
  <CSSTransition
    {...props}
    classNames="mappa"
    timeout={{ enter: 1000, exit: 600 }}
  />
);

const BoxMappa = ({ data: { mainTitle, mainSubtitle } }) => {
  const [region, setRegion] = useState("italy");
  const backToItaly = useCallback(() => setRegion("italy"), []);

  const nextRegion = useCallback(
    () =>
      setRegion((current) => {
        const index = regions.indexOf(current);
        if (index < regions.length - 2) {
          return regions[index + 1];
        } else {
          return regions[0];
        }
      }),
    []
  );

  const prevRegion = useCallback(
    () =>
      setRegion((current) => {
        const index = regions.indexOf(current);
        if (index > 0) {
          return regions[index - 1];
        } else {
          return regions[regions.length - 1];
        }
      }),
    []
  );

  const selectRegion = useCallback((event) => {
    const targetKey = Object.keys(targetsMap).find((key) =>
      event.target.closest(key)
    );
    if (targetKey) {
      setRegion(targetsMap[targetKey]);
    }
  }, []);

  return (
    <div className="section box-mappa mb-5 pb-5">
      <div className="container">
        {mainTitle && mainTitle.value ? (
          <div className="row">
            <div className="col-lg-10 offset-lg-1 text-md-center">
              <h2 className="box-mappa__title">{mainTitle.value}</h2>
            </div>
          </div>
        ) : null}
        {mainSubtitle && mainSubtitle.value ? (
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div
                className="box-mappa__subtitle text-md-center"
                dangerouslySetInnerHTML={{ __html: mainSubtitle.value }}
              ></div>
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-12">
            <div className="box-mappa__box">
              <button className="box-mappa__close" onClick={backToItaly}>
                <i
                  className={`nj-icon nj-icon-cross nj-icon--white size-72 ${
                    region === "italy" ? "d-none" : ""
                  }`}
                ></i>
              </button>
              <TransitionGroup>
                {region === "italy" ? (
                  <FadeTransition key="italy">
                    <div className="box-mappa__region-wrapper p-5 text-center">
                      <Italymap
                        onClick={selectRegion}
                        className="box-mappa__map"
                      />
                    </div>
                  </FadeTransition>
                ) : (
                  <FadeTransition key={region}>
                    <div className="box-mappa__region-wrapper p-5">
                      <div className="row h-100">
                        <div className="col-12 col-md-5 d-flex align-items-center order-md-2">
                          <RegionMap
                            region={region}
                            className="box-mappa__regione"
                          />
                        </div>
                        <div className="col-12 col-md-6 mt-5">
                          <RegionData region={region} />
                        </div>
                      </div>
                      <button
                        className="box-mappa__control box-mappa__control--prev"
                        onClick={prevRegion}
                      >
                        <i className="nj-icon nj-icon-arrowleft nj-icon--white size-72"></i>
                      </button>
                      <button
                        className="box-mappa__control box-mappa__control--next"
                        onClick={nextRegion}
                      >
                        <i className="nj-icon nj-icon-arrowright nj-icon--white size-72"></i>
                      </button>
                    </div>
                  </FadeTransition>
                )}
              </TransitionGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoxMappa;
export const boxMappaFragment = graphql`
  fragment BoxMappaFragment on LiferayBoxmappa {
    siteId
    articleId
    mainTitle {
      value
    }
    mainSubtitle {
      value
    }
  }
`;
