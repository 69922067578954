import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Link } from './link'


const LaunchBox = ({ data: { mainTitle, cardTitle } }) => {
  return (
    <div className="launch-box">
      <div className="container">
        {mainTitle && 
          (mainTitle.value || (mainTitle.content && mainTitle.content.mainSubtitle && mainTitle.content.mainSubtitle.value)) ? (
          <div className="row">
            <div className="col-12 mb-2">
              {mainTitle.value && (
                <div className="launch-box__mainTitle" dangerouslySetInnerHTML={{ __html: mainTitle.value}}></div>
              )}
              {mainTitle.content && mainTitle.content.mainSubtitle && mainTitle.content.mainSubtitle.value ? (
                <div className="mb-5" dangerouslySetInnerHTML={{__html: mainTitle.content.mainSubtitle.value}}></div>
              ) : null}
            </div>
          </div>
        ): null}
        <div className="row justify-content-md-center">
          {cardTitle.map((card, i) => (
            <div className="col-12 col-md-6 pb-5 mb-lg-5" key={i}>
              <Link to={card.content.cardLink && card.content.cardLink.value} className="launch-box__container">
                <Img className="launch-box__img" image={getImage(card.content.cardImage.node)}
                  alt={card.content.cardImage.node.description || ''} />
                <div  className="launch-box__title">
                  {card.content && card.content.cardLink && card.content.cardLink.value ? (
                    <i className="nj-icon nj-icon-arrowright nj-icon--circle nj-icon--blue-corporate size-60"></i>
                  ): null}
                  <h3>{card.value}</h3>
                </div>
              </Link>
              {card.content.cardDescription && card.content.cardDescription.value ? (
                <div className="text-center mx-sm-5 mx-2 mt-4" 
                  dangerouslySetInnerHTML={{__html: card.content.cardDescription.value}}></div>
              ): null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default LaunchBox;
export const launchBoxFragment = graphql`fragment ChoiceselectorFragment on LiferayChoiceselector{
  siteId
  articleId
  mainTitle {
    value
    content {
      mainSubtitle {
        value
      }
    }
  }
  cardTitle {
    value
    content {
      cardImage {
        node {
          description
          gatsbyImageData(width: 538)
        }
      }
      linkIconPresent
      cardDescription {
        value
      }
      cardLink {
        value
      }
    }
  }
}`;
